import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'

import { slugify } from '../utils'

const NotFoundPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(query)
  const { title } = data.site.siteMetadata

  return (
    <section className="min-vh-100" style={{ position: "fixed", top: 0, left: 0, right: 0 }}>
      <Helmet title={`${intl.formatMessage({ id: "notFound404" })} · ${title}`} />

      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center no-gutters min-vh-100">
          <div className="col-12 col-md-6 col-lg-4 py-8 py-md-11">
            <h1 className="display-3 font-weight-bold">
              <FormattedMessage id="notFound404Title" />
            </h1>
            <p className="mb-5 text-muted">
              <FormattedMessage id="notFound404Subline" />
            </p>
            <Link to={slugify(intl.locale)} className="btn btn-primary">
              <FormattedMessage id="notFound404Homelink" />
            </Link>
          </div>
          <div className="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">
            <div className="h-100 w-cover bg-cover position-relative">
              <Img fluid={data.contentfulAsset.fluid} style={{ width: "100%", height: "100%" }} />
              <Gradient direction="bottom" />
              <Gradient direction="top" />
            </div>
            <div className="shape shape-left shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H100V386L50 1158V1544H0V0Z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

const Gradient = ({ direction }) => (
  <div style={{
    height: "30vh",
    background: `linear-gradient(to ${direction}, #fffd 0%, #fffa 30%, #fff0 100%)`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    [direction]: "unset"
  }} />
)

const query = graphql`
  {
    site {
      siteMetadata {
				title
      }
    }
    contentfulAsset(contentful_id: {eq: "5Ol6ZC7QmYSHlASDuuqJHC"}) {
      fluid(maxWidth: 1600, maxHeight: 2000) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export default NotFoundPage